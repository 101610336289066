.minno-element.Minno {
  background-color: black;
  min-height: 100vh;
}

.minno-element.Minno body.minno-body {
  margin: 0;
  font-family: "Inter", sans-serif;
  color: #ffffff;
}

.minno-element.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
}

.minno-element.announcement {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 0px 0px;
  background-color: #00dcff;
  width: 100%;
  margin: auto;
}

.minno-element#announcement-text {
  font-size: 18px;
  font-weight: 600;
  color: #0c1b09;
  text-align: center;
}

.minno-element.logo-header {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  gap: 16px;
  align-items: center;
}

.minno-element#template-logo {
  width: 110px;
  height: auto;
}

.minno-element#vertical-line {
  font-size: 18px;
  color: #3c3c3c;
}

.minno-element#natural-logo {
  font-size: 22px;
  color: #ffffff;
}

.minno-element.nav-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.minno-element.icon-button {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #dcdcdc;
  border-radius: 6px;
}

.minno-element.icon-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.minno-element.icon-button svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.minno-element.icon-button:hover svg {
  color: #ffffff;
}

.minno-element.menu-container {
  position: relative;
}

.minno-element.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #0c1b09;
  border: 1px solid #3d493a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 6px 6px;
  min-width: 210px;
  z-index: 10000;
  border-radius: 12px;
}

.minno-element.dropdown-menu.show {
  display: block;
}

.minno-element.dropdown-menu button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 12px;
  border: none;
  background-color: #0c1b09;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  color: #ffffff;
  border-radius: 6px;
}

.minno-element.dropdown-menu button:hover {
  background-color: #24391f;
}

.minno-element.dropdown-menu button.highlighted {
  background-color: rgba(127, 242, 87, 0.1);
  box-shadow: 0 0 0 2px rgba(127, 242, 87, 0.5);
  transition: all 0.3s ease-out;
}

.minno-element.dropdown-menu button.highlighted {
  position: relative;
  top: auto;
  right: auto;
  width: 100%;
  z-index: auto;
  display: flex;
}

.minno-element.toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}

.minno-element.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.minno-element.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.minno-element.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0c1b09;
  transition: 0.4s;
  border-radius: 34px;
}

.minno-element.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.minno-element input:checked + .slider {
  background-color: #7ff257;
  box-shadow: 0 0 10px rgba(127, 242, 87, 0.3);
}

.minno-element input:checked + .slider:before {
  transform: translateX(20px);
}

.minno-element.contact-link {
  margin-left: 10px;
}

.minno-element.contact-link a {
  color: #dcdcdc;
  font-size: 16px;
  text-decoration: none;
  font-weight: 500;
}

.minno-element.contact-link a:hover {
  color: #ffffff;
}

.minno-element.hero {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 60px 40px;
  max-width: 1100px;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .minno-element.hero {
    flex-direction: column;
    padding: 40px 20px;
  }

  .minno-element.text-content,
  .minno-element.image-container {
    max-width: 100%;
    width: 100%;
  }

  .minno-element.image-container {
    justify-content: center;
    margin-top: 30px;
  }
}

.minno-element.text-content {
  max-width: 50%;
  text-align: left;
  flex: 1;
}

.minno-element#header {
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 48px;
  line-height: 1.2;
  margin-bottom: 12px;
}

.minno-element.highlight {
  color: #00dcff;
}

.minno-element#sub-header {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 32px;
  color: #dcdcdc;
}

.minno-element.email-form {
  display: flex;
  padding: 10px;
  background-color: #252525;
  border-radius: 8px;
  overflow: hidden;
}

.minno-element.email-form-2 {
  display: flex;
  padding: 12px;
  background-color: #252525;
  border-radius: 8px;
  overflow: hidden;
}

.minno-element.input-button-group {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.minno-element#email,
.minno-element#email-2 {
  flex: 1;
  font-size: 18px;
  padding: 12px 16px;
  border: none;
  background-color: #252525;
  color: #ffffff;
  cursor: text;
  outline: none;
}

.minno-element#email-2 {
  background-color: #252525;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #252525 inset;
  box-shadow: 0 0 0px 1000px #252525 inset;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
}

input,
input:focus {
  outline: none;
}

.minno-element#email::placeholder,
.minno-element#email-2::placeholder {
  color: #90968e;
}

.minno-element.input-button {
  padding: 12px 24px;
  font-weight: 600;
  font-size: 18px;
  background-color: #00dcff;
  color: #0c1b09;
  border: none;
  cursor: pointer;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.5);
  border-radius: 100px;
}

.minno-element.image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  border-radius: 8px;
  padding: 20px;
}

.minno-element.background-image {
  max-width: 450px;
  width: 100%;
  pointer-events: none;
  user-select: none;
}

.minno-element.features-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px 20px;
}

.minno-element#features-text {
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 46px;
  color: #ffffff;
  text-align: center;
}

.minno-element.features-section {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 80px;
}

.minno-element.features-2 {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.minno-element.feature-item-2 {
  flex: 1;
  min-width: 0;
  text-align: left;
  padding: 28px;
  background-color: #132427;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.minno-element.feature-item-2 img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 10px;
}

.minno-element.founders {
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px 0 10px;
}

.minno-element.story {
  max-width: 800px;
  margin: 0 auto;
  padding: 5px 0px;
  margin-bottom: 100px;
}

.minno-element#story-heading {
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 46px;
  color: #ffffff;
  text-align: center;
}

.minno-element#story-heading .highlight {
  color: #00dcff;
}

.minno-element#story-text {
  font-size: 18px;
  line-height: 1.6;
  color: #dcdcdc;
  margin-bottom: 32px;
  text-align: left;
}

.minno-element.story ul {
  list-style-type: none;
  padding-left: 20px;
  line-height: 1.6;
  color: #dcdcdc;
  font-size: 18px;
  margin-bottom: 46px;
}

.minno-element.story ul li {
  position: relative;
  padding-left: 20px;
  color: #dcdcdc;
  line-height: 1.6;
  margin-bottom: 10px;
  font-size: 18px;
}

.minno-element.story ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  background-color: #00dcff;
}

.minno-element.story ul li strong {
  font-weight: 700;
  color: #ffffff;
}

.minno-element.features {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 30px;
}

.minno-element.feature-item {
  font-family: "Inter", sans-serif;
  flex: 1;
  text-align: left;
  padding: 28px;
  background-color: #000000;
  border: 1px solid #3c3c3c;
  border-radius: 8px;
}

.minno-element#feature-heading {
  font-size: 18px;
  font-family: "Inter", sans-serif;
  margin-top: 6px;
  color: #ffffff;
}

.minno-element#feature-text {
  font-size: 18px;
  line-height: 1.5;
  color: #dcdcdc;
  margin-top: -4px;
  margin-bottom: 0px;
}

.minno-element#icon {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
  pointer-events: none;
  user-select: none;
}

.minno-element.dev-section-container {
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 80px;
}

.minno-element.dev-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.minno-element footer {
  padding: 40px 0;
  background-color: #000000;
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #3c3c3c;
}

.minno-element.inner-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 40px;
}

.minno-element.footer-logo {
  display: flex;
  align-items: center;
  gap: 24px;
}

.minno-element a {
  color: #dcdcdc;
  text-decoration: none;
  text-shadow: 0 0 5px rgba(87, 239, 242, 0.3);
}

.minno-element#logo-text {
  text-align: center;
  font-weight: 500;
  color: #dcdcdc;
  font-size: 18px;
  margin-bottom: 24px;
}

.minno-element.logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 56px;
  margin-top: 32px;
  margin-bottom: 56px;
}

.minno-element.trusted-by-text {
  text-align: center;
  color: #dcdcdc;
  font-size: 18px;
  margin-bottom: 24px;
  font-weight: bold;
}

.minno-element.logo-images {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 56px;
}

.minno-element#leapyear {
  width: auto;
  height: 30px;
  pointer-events: none;
  user-select: none;
  filter: brightness(0) invert(1);
}

.minno-element#pioneer {
  width: auto;
  height: 65px;
  pointer-events: none;
  user-select: none;
  filter: brightness(0) invert(1);
}

.minno-element#icon {
  width: auto;
  height: 24px;
}

@media (max-width: 400px) {
  .minno-element.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
  }

  .minno-element.logo-header {
    padding-left: 0px;
    flex: 1;
    text-align: left;
    flex-direction: column;
  }

  .minno-element.hero {
    flex-direction: column;
    padding: 30px 10px;
  }

  .minno-element.text-content {
    text-align: center;
    max-width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .minno-element#header {
    font-size: 32px;
  }

  .minno-element#sub-header {
    font-size: 18px;
  }

  .minno-element.image-container {
    justify-content: center;
  }

  .minno-element.background-image {
    max-width: 100%;
  }

  .minno-element.features {
    flex-direction: column;
    gap: 20px;
  }

  .minno-element.feature-item {
    padding: 20px;
  }

  .minno-element.logos {
    gap: 20px;
  }

  .minno-element.inner-footer {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .minno-element.footer-logo {
    margin-bottom: 10px;
  }

  .minno-element#texas {
    height: 50px;
  }

  .minno-element#mit {
    height: 20px;
  }

  .minno-element.input-button-group {
    flex-direction: column;
    gap: 10px;
  }

  .minno-element.input-button {
    padding: 10px 15px;
    font-size: 16px;
  }

  .minno-element#email,
  .minno-element#email-2 {
    padding: 10px;
    font-size: 16px;
  }

  .minno-element.contact-link a {
    color: #8f8f8f;
    text-decoration: none;
    padding-right: 0px;
  }
}

.minno-element.download-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  background-color: #111111; /* Slightly lighter than black for contrast */
}

.minno-element.download-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 100px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition:
    background-color 0.3s,
    transform 0.3s;
  text-decoration: none;
}

.minno-element.download-button:hover {
  background-color: #f0f0f0;
  transform: scale(1.05);
}

.minno-element.download-button .apple-icon {
  margin-right: 10px;
  font-size: 24px;
}

/* General Tablet Style */
@media (min-width: 401px) and (max-width: 1024px) {
  .minno-element.hero {
    flex-direction: column;
    padding: 40px 20px;
  }

  .minno-element.text-content {
    text-align: center;
    max-width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .minno-element#header {
    font-size: 36px;
  }

  .minno-element#sub-header {
    font-size: 20px;
  }

  .minno-element.image-container {
    justify-content: center;
  }

  .minno-element.background-image {
    max-width: 80%;
  }

  .minno-element.features {
    flex-direction: column;
    gap: 30px;
  }

  .minno-element.feature-item {
    padding: 25px;
  }

  .minno-element.logos {
    gap: 40px;
  }

  .minno-element#texas {
    height: 60px;
  }

  .minno-element#mit {
    height: 20px;
  }

  .minno-element.inner-footer {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .minno-element.footer-logo {
    margin-bottom: 15px;
  }

  .minno-element.input-button-group {
    flex-direction: column;
    gap: 15px;
  }

  .minno-element.input-button {
    padding: 10px 20px;
    font-size: 18px;
  }

  .minno-element#email,
  .minno-element#email-2 {
    padding: 15px;
    font-size: 18px;
  }

  .minno-element.contact-link a {
    padding-right: 15px;
  }
}

@media (max-width: 768px) {
  .minno-element#vertical-line,
  .minno-element#template-logo {
    display: none;
  }

  .minno-element.logo-header {
    padding-left: 0;
    align-items: flex-start;
  }
}

.minno-element.icon-button.highlighted,
.minno-element.dropdown-menu button.highlighted,
.minno-element.toggle-container.highlighted,
.minno-element#copy-link.highlighted,
.minno-element#menu-button.highlighted,
.minno-element#email.highlighted,
.minno-element.features.highlighted,
.minno-element.founders.highlighted,
.minno-element.contact-link a.highlighted {
  background-color: rgba(127, 242, 87, 0.1);
  box-shadow:
    0 0 0 2px rgba(127, 242, 87, 0.5),
    0 0 10px rgba(127, 242, 87, 0.3);
  border-radius: 6px;
  transition: all 0.3s ease-out;
}

/* Styles that need to be global can be left outside the .minno-element wrapper */
body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

.minno-element.fading {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

@media (max-width: 768px) {
  .minno-element.hide-mobile {
    display: none;
  }

  .minno-element.announcement {
    padding: 10px 0;
  }
}

.minno-element.close-tooltip {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}

/* Add this at the end of your Clippy.css file */
.minno-element#features-tooltip {
  position: absolute;
  width: 1px;
  height: 1px;
  background: transparent;
}

.minno-element.icon-button svg,
.minno-element.dropdown-menu button svg,
.minno-element.contact-link a svg {
  color: #8f8f8f;
  transition: color 0.3s ease;
}

.minno-element.icon-button:hover svg,
.minno-element.dropdown-menu button:hover svg,
.minno-element.contact-link a:hover svg {
  color: #ffffff;
}

/* Increase icon size for mobile and tablet */
@media (max-width: 1024px) {
  .minno-element.icon-button {
    font-size: 24px;
    width: 48px;
    height: 48px;
  }
}

/* Ensure menu and tooltips are at the front */
.minno-element.dropdown-menu {
  z-index: 10000;
}

.minno-element.react-tooltip {
  z-index: 9999 !important;
}

@media (max-width: 1024px) {
  .minno-element.header-content {
    flex-direction: column;
    align-items: center;
  }

  .minno-element.nav-actions {
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .minno-element.react-tooltip {
    max-width: 80vw !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

@media (max-width: 768px) {
  .minno-element.dev-section {
    flex-direction: column !important;
  }
}

@media (max-width: 768px) {
  .minno-element.story {
    padding: 20px !important;
  }
}

@media (max-width: 768px) {
  .minno-element.logos {
    flex-direction: column !important;
  }
}

/* Add or modify these media queries at the end of your CSS file */
@media (max-width: 480px) {
  .minno-element.header-content {
    padding: 10px 15px;
  }

  .minno-element.nav-actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .minno-element.icon-button {
    font-size: 18px;
    width: 36px;
    height: 36px;
  }

  .minno-element.contact-link a {
    font-size: 14px;
  }

  .minno-element#natural-logo {
    font-size: 18px;
  }
}

/* Add these styles for the icons */
.minno-element.icon-button svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

@media (max-width: 768px) {
  .minno-element.icon-button svg {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 480px) {
  .minno-element.icon-button svg {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 360px) {
  .minno-element.header-content {
    padding: 10px;
  }

  .minno-element.nav-actions {
    gap: 5px;
  }

  .minno-element.icon-button {
    font-size: 16px;
    width: 32px;
    height: 32px;
  }

  .minno-element.contact-link a {
    font-size: 12px;
  }

  .minno-element#natural-logo {
    font-size: 16px;
  }
}

.minno-element.icon-button {
  /* ... existing styles ... */
  color: #dcdcdc; /* Ensure the icon color is #DCDCDC */
  display: flex;
  justify-content: center;
  align-items: center;
}

.minno-element.icon-button svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

@media (max-width: 768px) {
  .minno-element.icon-button svg {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 480px) {
  .minno-element.icon-button svg {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 360px) {
  .minno-element.header-content {
    padding: 10px;
  }

  .minno-element.nav-actions {
    gap: 5px;
  }

  .minno-element.icon-button {
    font-size: 16px;
    width: 32px;
    height: 32px;
  }

  .minno-element.contact-link a {
    font-size: 12px;
  }

  .minno-element#natural-logo {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .minno-element.header-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    width: 100%;
    box-sizing: border-box;
  }

  .minno-element.features-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
  }

  .minno-element.main-features {
    padding: 20px;
  }

  .minno-element.dev-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .minno-element.story {
    padding: 20px;
  }

  .minno-element.logos {
    flex-direction: column;
  }

  .minno-element.dev-section-container {
    padding: 20px;
  }

  .minno-element.logo-header {
    padding-left: 0;
    flex-direction: column;
    align-items: left;
  }

  .minno-element.nav-actions {
    margin-top: 0;
    gap: 10px;
    display: flex;
    align-items: center;
  }

  .minno-element.icon-button {
    width: 32px;
    height: 32px;
    font-size: 18px;
  }

  .minno-element.contact-link {
    margin-left: 0;
  }

  .minno-element.contact-link a {
    font-size: 14px;
    white-space: nowrap;
  }

  /* Ensure the menu is visible */
  .minno-element.menu-container {
    display: flex;
  }

  .minno-element.dropdown-menu {
    right: 0;
    left: auto;
    z-index: 1003;
  }
}

/* Add or modify these media queries at the end of your CSS file */
@media (max-width: 480px) {
  .minno-element.header-content {
    padding: 10px 15px;
  }

  .minno-element.nav-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
  }

  .minno-element.icon-button {
    font-size: 18px;
    width: 36px;
    height: 36px;
  }

  .minno-element.contact-link a {
    font-size: 14px;
  }

  .minno-element#natural-logo {
    font-size: 18px;
  }
}

@media (max-width: 360px) {
  .minno-element.header-content {
    padding: 10px;
  }

  .minno-element.icon-button {
    font-size: 16px;
    width: 32px;
    height: 32px;
  }

  .minno-element.contact-link a {
    font-size: 12px;
  }

  .minno-element#natural-logo {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .minno-element.inner-footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .minno-element.footer-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
  }

  .minno-element.contact-link {
    margin: 0 5px;
  }

  .minno-element.contact-link a {
    font-size: 14px;
    padding: 5px 10px;
    white-space: nowrap;
  }

  .minno-element.input-email-form-2 {
    width: 100%;
  }

  .minno-element.email-form-2 {
    flex-direction: column;
  }

  .minno-element#email-2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .minno-element.inner-footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .minno-element.footer-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
  }

  .minno-element.contact-link {
    margin: 0 5px;
  }

  .minno-element.contact-link a {
    font-size: 14px;
    padding: 5px 10px;
    white-space: nowrap;
  }

  .minno-element.input-email-form-2 {
    width: 100%;
  }

  .minno-element.email-form-2 {
    flex-direction: column;
  }

  .minno-element#email-2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .minno-element.inner-footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .minno-element.footer-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
  }

  .minno-element.contact-link {
    margin: 0 5px;
  }

  .minno-element.contact-link a {
    font-size: 14px;
    padding: 5px 10px;
    white-space: nowrap;
  }

  .minno-element.input-email-form-2 {
    width: 100%;
  }

  .minno-element.email-form-2 {
    flex-direction: column;
  }

  .minno-element#email-2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .minno-element.inner-footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .minno-element.footer-logo {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
  }

  .minno-element.contact-link {
    margin-left: 0;
    margin-bottom: 0;
  }

  .minno-element.contact-link a {
    font-size: 14px;
    padding: 5px 10px;
  }

  .minno-element.input-email-form-2 {
    width: 100%;
  }

  .minno-element.email-form-2 {
    flex-direction: column;
  }

  .minno-element#email-2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .minno-element.inner-footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .minno-element.footer-logo {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }

  .minno-element.contact-link {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .minno-element.contact-link a {
    font-size: 14px;
  }

  .minno-element.input-email-form-2 {
    width: 100%;
  }

  .minno-element.email-form-2 {
    flex-direction: column;
  }

  .minno-element#email-2 {
    margin-bottom: 10px;
  }
}

/* Styles that need to be global can be left outside the .minno-element wrapper */
body {
  margin: 0;
  font-family: "Inter", sans-serif;
}
