.blog-main {
  background-color: #0c1b09;
  color: #ffffff;
  font-family: "Inter", sans-serif;
}

.blog-banner {
  font-family: "Jersey 10", sans-serif;
  font-weight: 400;
  background-color: #ffb321;
  color: #0c1b09;
  padding: 16px 0;
  font-size: 24px;
  overflow: hidden;
  white-space: nowrap;
}

.blog-banner-content {
  display: inline-block;
  animation: scroll-left 60s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.blog-main main {
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px 40px;
}

#blog-title {
  font-size: 40px; /* Increased from 36px */
  margin-bottom: 64px;
  font-family: "Bricolage Grotesque", sans-serif;
  text-align: center;
}

.main-highlight {
  display: flex;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 100px;
  transition: transform 0.2s ease-in-out;
}

.main-highlight:hover {
  transform: translateY(-5px);
}

.main-highlight-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.main-highlight-image {
  flex: 1.5; /* Increased from 1 to 1.5 to make it larger */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #3d493a;
  transition: border-color 0.2s ease-in-out;
}

.main-highlight:hover .main-highlight-image {
  border-color: #7ff257;
}

.main-highlight img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 400px; /* Adjust this value as needed */
}

.main-highlight h2 {
  font-size: 32px;
  margin-bottom: 10px;
}

.author-info {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-top: 5px;
}

.author-info p {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.author-image {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 12px; /* Increased from 8px */
  object-fit: cover;
}

.author-name {
  margin-right: 8px; /* Added space between author name and date */
}

.author-date-separator {
  margin: 0 8px; /* Added space around the separator */
}

.main-highlight .author-info {
  margin-bottom: 20px;
}

.read-more-btn {
  padding: 12px 24px;
  width: fit-content;
  font-weight: 600;
  font-size: 18px;
  background-color: #7ff257;
  color: #0c1b09;
  border: none;
  cursor: pointer;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.5);
  border-radius: 100px;
}

.other-highlights {
  display: flex;
  gap: 40px;
  margin-bottom: 80px;
}

.highlight-post {
  flex: 1;
  text-decoration: none;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in-out;
}

.highlight-post:hover {
  transform: translateY(-5px);
}

.highlight-post-image {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid #3d493a; /* Added this line */
  transition: border-color 0.2s ease-in-out;
}

.highlight-post:hover .highlight-post-image {
  border-color: #7ff257;
}

.highlight-post-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.highlight-post:hover .highlight-post-image img {
  transform: scale(1.05);
}

.highlight-post-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.highlight-post h3 {
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 20px;
  margin-bottom: 8px;
  line-height: 1.3;
}

.latest-posts {
  margin-bottom: 60px;
}

.latest-posts h2 {
  margin-top: 0px;
  margin-bottom: 40px;
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 32px;
}

.latest-posts-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.latest-post {
  background-color: #0c1b09;
  border: 1px solid #3d493a;
  padding: 32px 32px;
  border-radius: 10px;
  text-decoration: none;
  color: #ffffff;
  transition:
    transform 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.latest-post:hover {
  transform: translateY(-5px);
  border-color: #7ff257;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.latest-post h3 {
  font-size: 20px;
  margin-bottom: 5px;
  transition: color 0.2s ease-in-out;
}

.author-image,
.blog-author-image {
  width: 24px; /* Increased from 20px */
  height: 24px; /* Increased from 20px */
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
}

.main-highlight .author-image,
.main-highlight .blog-author-image {
  width: 24px; /* Increased from 20px */
  height: 24px; /* Increased from 20px */
}

.read-more-btn {
  padding: 12px 24px;
  width: fit-content;
  font-weight: 600;
  font-size: 18px;
  background-color: #7ff257;
  color: #0c1b09;
  border: none;
  cursor: pointer;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.5);
  border-radius: 100px;
}

.highlight-post {
  flex: 1;
  text-decoration: none;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in-out;
}

.highlight-post:hover {
  transform: translateY(-5px);
}

.highlight-post-image {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid #3d493a; /* Added this line */
  transition: border-color 0.2s ease-in-out;
}

.highlight-post:hover .highlight-post-image {
  border-color: #7ff257;
}

.highlight-post-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.highlight-post:hover .highlight-post-image img {
  transform: scale(1.05);
}

.highlight-post-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.highlight-post h3 {
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 20px;
  margin-bottom: 8px;
  line-height: 1.3;
}

.latest-posts h3 {
  margin-top: 0px;
  font-size: 24px;
}

.latest-posts-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.latest-post {
  background-color: #0c1b09;
  border: 1px solid #3d493a;
  padding: 32px 32px;
  border-radius: 10px;
  text-decoration: none;
  color: #ffffff;
  transition:
    transform 0.2s ease-in-out,
    border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.latest-post:hover {
  transform: translateY(-5px);
  border-color: #7ff257;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.latest-post h3 {
  font-size: 20px;
  margin-bottom: 5px;
  transition: color 0.2s ease-in-out;
}

.author-info {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-top: 5px;
}

.author-info p {
  margin: 0;
  padding: 0;
}

.author-image {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
}

.main-highlight .author-info {
  margin-bottom: 20px;
}

.highlight-post .author-info {
  margin-top: 5px;
}

.latest-post .author-info {
  margin-top: 5px;
}

.blog-author-image {
  width: 24px; /* Increased from 20px */
  height: 24px; /* Increased from 20px */
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
}

.main-highlight-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

@media (max-width: 1024px) {
  .blog-main main {
    padding: 40px 20px;
  }
}

@media (max-width: 768px) {
  .main-highlight {
    flex-direction: column-reverse; /* Image on top, content below */
  }

  .main-highlight-content,
  .main-highlight-image {
    flex: none;
  }

  .main-highlight-image {
    margin-bottom: 20px;
  }

  .main-highlight img {
    max-height: 300px; /* Adjust for mobile */
  }

  .other-highlights {
    flex-direction: column;
  }

  .highlight-post {
    margin-bottom: 40px;
  }

  .highlight-post-image {
    padding-bottom: 66.67%; /* 3:2 aspect ratio for mobile */
  }

  .blog-main main {
    padding: 30px 20px;
  }

  .main-highlight:hover {
    transform: none;
  }

  .highlight-post:hover {
    transform: none;
  }

  .highlight-post:hover .highlight-post-image {
    border-color: #3d493a;
  }

  .highlight-post:hover .highlight-post-image img {
    transform: none;
  }

  .latest-post:hover {
    transform: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }
}
