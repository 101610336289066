.table-container {
  overflow-x: auto;
}

.table-container .styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 18px;
  text-align: left;
  color: #ffffff;
  background-color: #193015;
  border-bottom: 1px solid #193015;
  border-radius: 10px;
}


.table-container .styled-table thead tr {
  background-color: #24391f;
  color: #ffffff;
  text-align: left;
  font-size: 16px;

}

.table-container .styled-table th,
.table-container .styled-table td {
  padding: 13px 15px;
}

.table-container .styled-table tbody tr {
  border-bottom: 1px solid #264622;
}

.table-container .styled-table tbody tr:nth-of-type(even) {
  background-color: #193015; /* Dark green color */
}

.table-container .styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #193015;
}