.Clippy-rotating-text-box {
  background-color: #1d2e19;
  border-radius: 8px;
  padding: 20px;
  max-width: 450px;
  width: 100%;
  box-sizing: border-box;
}

.Clippy-rotating-text-box .rotating-text {
  font-size: 18px;
  margin: 0 0 15px;
  text-align: left;
  color: #ffffff;
  display: inline-block;
  position: relative;
}

.Clippy-rotating-text-box .rotating-text::after {
  content: "|";
  position: absolute;
  right: 0; /* Changed from -1px to 0 */
  bottom: 0;
  color: #7ff257;
  animation: blink 1s step-end infinite;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.Clippy-rotating-text-box .button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.submit-btn,
.refresh-btn {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: transform 0.3s ease;
}

.submit-btn {
  padding: 12px 24px;
  font-weight: 600;
  font-size: 18px;
  background-color: #ffffff;
  color: #181818;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  position: relative;
  overflow: hidden;
}

.submit-btn:hover {
  transform: scale(1.05);
}

.restart-icon {
  width: 20px;
  height: 20px;
  font-size: 18px;
  margin-top: 12px;
  color: #ffffff;

  transition: transform 0.3s ease;
}

.demo-restart-button {
  background-color: #0c1b09;
  border: none;
  cursor: pointer;
  margin-top: 6px;
  margin-bottom: 6px;
}

.demo-restart-button:hover .restart-icon {
  transform: rotate(180deg);
}

/* This will make the icon spin back when not hovered */
.demo-restart-button .restart-icon {
  transform: rotate(0deg);
}

/* Remove the old .restart-icon styles */

/* Custom Tooltip Styles */
.react-tooltip.custom-tooltip {
  opacity: 1 !important;
  background-color: #24391f !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  font-size: 16px !important;
  line-height: 1.4 !important;
  max-width: 300px !important;
  font-weight: 500 !important;
  padding: 0 !important; /* Remove default padding */
}

.react-tooltip.custom-tooltip .tooltip-content {
  padding: 10px 14px !important; /* Add padding to the content */
}

.tooltip-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.done-button {
  background-color: #496d40;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 100px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.done-button:hover {
  background-color: #496d40;
  transform: scale(1.05);
}

/* Override react-tooltip default styles */
.react-tooltip {
  opacity: 1 !important;
  transition: opacity 0.3s ease-out !important;
}

.react-tooltip.fading {
  opacity: 0 !important;
}

.react-tooltip-arrow {
  display: none !important;
}

/* Media query for mobile and tablet devices */
@media (max-width: 1024px) {
  .Clippy-rotating-text-box {
    max-width: 100%;
    width: 100%;
  }
}
