.blog-post {
  background-color: #0c1b09;
  color: #ffffff;
  font-family: "Inter", sans-serif;
}

.blog-post-header {
  position: relative;
  height: 50vh;
  min-height: 300px;
  max-height: 600px;
  background: linear-gradient(to bottom, #1c2b19, #0c1b09);
}

.go-back-btn-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 40px;
}

.go-back-btn {
  position: relative;
  top: 40px;
  color: #dcdcdc;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  background-color: rgba(12, 27, 9, 0);
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: 0.3s ease;
}

.go-back-btn:hover {
  color: #ffffff;
  background-color: rgba(12, 27, 9, 0.5);
}

.blog-post-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(
    to bottom,
    rgba(12, 27, 9, 0),
    rgba(12, 27, 9, 1)
  );
}

.blog-post-info {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 40px;
}

.blog-post-info h1 {
  font-size: 48px;
  margin-bottom: 16px;
  font-family: "Bricolage Grotesque", sans-serif;
}

.blog-post-info .author-info {
  display: flex;
  align-items: center;
}

.blog-post-info .author-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  object-fit: cover;
}

.blog-post-info p {
  font-size: 18px;
  margin: 0;
}

.blog-post-content-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 0 auto;
  padding: 60px 40px;
}

.blog-post-content {
  flex: 1;
  max-width: calc(100% - 380px);
  margin-right: 40px;
}

.blog-content {
  line-height: 1.6;
  font-size: 18px;
}

/* Add styles for code blocks */
.blog-content pre,
.blog-content code {
  max-width: 100%;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.blog-content pre {
  background-color: #1c2b19;
  border: 1px solid #3d493a;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}

.blog-content code {
  background-color: #1c2b19;
  padding: 2px 5px;
  border-radius: 3px;
}

.blog-post-cta {
  width: 300px;
  flex-shrink: 0; /* Prevent CTA from shrinking */
}

.cta-wrapper {
  position: sticky;
  top: 40px;
}

.cta-box {
  background-color: #1c2b19;
  border: 1px solid #3d493a;
  border-radius: 10px;
  padding: 32px;
  margin-bottom: 24px;
}

.cta-box-email {
  background-color: #0c1b09;
  border: 1px solid #3c3c3c;
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 24px;
}

.cta-box-email h3 {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 20px;
  color: #ffffff;
}

.cta-box-email input {
  width: calc(100% - 38px); /* Subtract padding from width */
  padding: 16px 18px;
  margin-bottom: 16px;
  border: none;
  background-color: #1d2e19;
  color: #ffffff;
  border-radius: 8px;
  font-size: 18px;
}

.cta-box-email input::placeholder {
  color: #90968e;
}

.cta-box-email button {
  width: 100%;
  padding: 12px;
  background-color: #7ff257;
  color: #0c1b09;
  border: none;
  border-radius: 100px;
  font-weight: 600;
  font-size: 18px;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-box-email button:hover {
  background-color: #6cd648;
}

.cta-box-natural {
  background-color: #1c2b19;
  border-radius: 16px;
  padding: 32px;
}

.cta-box-natural h3 {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #ffffff;
}

.cta-box-natural p {
  font-size: 18px;
  color: #dcdcdc;
  margin-bottom: 16px;
  margin-top: 10px;
}

.cta-learn-more-btn {
  font-size: 18px;
  color: #7ff257;
  text-decoration: none;
  transition: color 0.3s ease;
}

.cta-learn-more-btn:hover {
  color: #6cd648;
}

@media (max-width: 1024px) {
  .blog-post-content-wrapper {
    flex-direction: column;
    padding: 40px 20px;
  }

  .blog-post-content {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }

  .blog-post-info {
    padding: 0 20px;
  }

  main {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }

  .blog-post-cta {
    width: 100%;
  }

  .cta-wrapper {
    position: static;
  }

  .go-back-btn-container {
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  .blog-post-header {
    height: 50vh;
    min-height: 300px;
  }

  .blog-post-info {
    bottom: 20px;
  }

  .blog-post-info h1 {
    font-size: 36px;
  }

  .blog-post-content-wrapper {
    padding: 30px 20px;
  }

  .go-back-btn {
    top: 20px; /* Adjust top margin for mobile */
  }
}
