.Minno-rotating-text-box {
  background-color: #252525;
  border-radius: 8px;
  padding: 20px;
  max-width: 450px;
  width: 100%;
  box-sizing: border-box;
}

.Minno-rotating-text-box .rotating-text {
  font-size: 18px;
  margin: 0 0 15px;
  text-align: left;
  color: #ffffff;
  display: inline-block;
  position: relative;
}

.Minno-rotating-text-box .rotating-text::after {
  content: "|";
  position: absolute;
  right: -5px;
  bottom: 0;
  color: #00dcff;
  animation: blink 1s step-end infinite;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.Minno-rotating-text-box .button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.submit-btn,
.refresh-btn {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: transform 0.3s ease;
}

.submit-btn {
  padding: 12px 24px;
  font-weight: 600;
  font-size: 18px;
  background-color: #ffffff;
  color: #181818;
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  position: relative;
  overflow: hidden;
}

.submit-btn:hover {
  transform: scale(1.05);
}

.refresh-btn {
  background-color: transparent;
  color: #ffffff;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.restart-icon {
  width: 20px;
  height: 20px;
  font-size: 18px;
  transition: transform 0.3s ease;
}

.refresh-btn:hover .restart-icon {
  transform: rotate(180deg);
}

.refresh-btn .restart-icon {
  transform: rotate(0deg);
}



.fade-in {
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Scrollbar styles for WebKit browsers */
.table-container::-webkit-scrollbar {
  width: 8px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #555555;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #656565;
}
