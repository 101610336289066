body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background-color: #0c1b09;
  color: #ffffff;
  max-width: 100%;
  overflow-x: hidden;
}

html {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Inter", sans-serif;
}

.demo-gif {
  width: 100%;
  height: 400px;
  border-radius: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.natural-action {
  position: fixed;
  bottom: 32px;
  right: 32px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #7ff257;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  z-index: 1001; /* Ensure it's above everything */
}

.hero-container {
  padding: 40px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 0 auto;
  overflow: visible;
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .hero {
    padding-right: 0;
    margin-bottom: 40px;
    text-align: center;
  }

  .demo-menu-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .demo-menu {
    max-width: 100%;
    width: 100%;
  }
}

.hero {
  flex: 1;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  flex-direction: column;
  padding-right: 40px;
}

.main-demo-button {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #3c3c3c;
  border-radius: 100px;
  padding: 6px 16px;
  font-weight: 600;
  cursor: pointer;
}

.demo-menu {
  max-width: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 28px;
  border-radius: 12px;
  box-shadow: 0 2px 5px rgba(8, 61, 61, 0.03);
  background-color: #193015;
  max-height: 400px; /* Add this line */
  overflow-y: auto; /* Add this line */
}

/* Add these styles for a custom scrollbar */
.demo-menu::-webkit-scrollbar {
  width: 8px;
}

.demo-menu::-webkit-scrollbar-track {
  background: #132410;
  border-radius: 10px;
}

.demo-menu::-webkit-scrollbar-thumb {
  background: #2a4c23;
  border-radius: 10px;
}

.demo-menu::-webkit-scrollbar-thumb:hover {
  background: #3a6b30;
}

/* For Firefox */
.demo-menu {
  scrollbar-width: thin;
  scrollbar-color: #2a4c23 #132410;
}

.demo-menu.highlighted {
  box-shadow:
    0 0 0 4px rgba(127, 242, 87, 1),
    0 2px 5px rgba(8, 61, 61, 0.03);
}

.demo-input-container {
  position: relative;
  margin-bottom: 16px;
}

.demo-input {
  width: 100%;
  color: #637360;
  border-radius: 10px;
  font-size: 18px;
  resize: none;
  outline: none;
  transition: border-color 0.3s ease;
  caret-color: #7ff257;
  pointer-events: none;
}

.demo-input:empty::before {
  content: attr(data-placeholder);
  color: rgba(255, 255, 255, 0.4);
}

.demo-input-cursor {
  display: inline-block;
  width: 2px;
  height: 24px;
  background-color: #ffffff;
  animation: blink 0.7s infinite;
  vertical-align: middle;
  margin-left: 2px;
}

@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.demo-menu button {
  padding: 12px 20px;
  font-weight: 600;
  border: 1px solid #e0e0e0;
  border-radius: 100px;
  background-color: #ffffff;
  font-size: 18px;
  color: #0C1B09;
  box-shadow: 0 1px 0px rgba(8, 61, 61, 0.05);
  margin-bottom: 24px;
}

.demo-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 200px; /* Adjust this value as needed */
}

.demo-button {
  flex: 0 0 auto;
  text-align: center; 
  padding: 12px 20px;
  font-weight: 600;
  border: 1px solid #435D3D;
  border-radius: 100px;
  background-color: #193015;
  font-size: 18px;
  color: #ffffff;
  box-shadow: 0 1px 0px rgba(8, 61, 61, 0.05);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.demo-button:hover {
  background-color: #24391f;
}

/* Remove horizontal scrollbar styles */
.demo-buttons::-webkit-scrollbar {
  width: 6px; /* Width of the vertical scrollbar */
  height: auto;
}

.demo-buttons::-webkit-scrollbar-track {
  background: #193015;
  border-radius: 10px;
}

.demo-buttons::-webkit-scrollbar-thumb {
  background: #203d1b;
  border-radius: 10px;
}

.demo-buttons::-webkit-scrollbar-thumb:hover {
  background: #203d1b;
}

/* Style the scrollbar for Firefox */
.demo-buttons {
  scrollbar-width: thin;
  scrollbar-color: #203d1b #193015;
}

.de {
  display: flex;
  flex-direction: column;
}

.demo-logo {
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 12px;
}

.demo-button {
  padding: 12px 20px;
  font-weight: 600;
  border: 1px solid #435D3D;
  border-radius: 100px;
  background-color: #193015;
  font-size: 18px;
  color: #ffffff;
  box-shadow: 0 1px 0px rgba(8, 61, 61, 0.05);

}



.demo-menu-header {
  margin-top: 0px;
  margin-bottom: 0px;
}

.demo-recent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.recent-header {
  font-weight: 600;
  font-size: 16px;
  color: #dcdcdc;
  margin: 0px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.demo-recent ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
}

.demo-recent ul li {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 500;
}

.demo-recent ul li:last-child {
  margin-bottom: 0;
}

.ruler-lines {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 1;
}

.ruler-lines::before,
.ruler-lines::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #f4f4f4;
}

.ruler-lines::before {
  left: 0;
}

.ruler-lines::after {
  right: 0;
}

.quick-features {
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 100px;
}

.custom-button {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

.custom-button div {
  padding: 8px 16px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.custom-button.bg-black div {
  background-color: black;
  color: white;
}

.custom-button.bg-black:hover div {
  background-color: #333;
}

.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 999;
  border-bottom: 1px solid #e0e0e0;
}

.nav {
  padding: 0px 32px;
}

.main-content {
  padding-top: 0;
}

/* New styles for the trusted-by section */
.trusted-by-section {
  margin-top: 40px;
}

.trusted-by-text {
  font-size: 16px;
  color: #686868;
  margin-bottom: 16px;
}

.trusted-by-images {
  display: flex;
  gap: 20px;
  align-items: center;
}

.trusted-by-image {
  max-width: 120px;
  height: auto;
}


.natural-action img {
  width: 24px;
  height: 24px;
}

.home {
  text-align: left;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  text-align: center;
}

.announcement {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 0px 0px;
  background-color: #f9b5ff;
  width: 100%;
  margin: auto;
}

#announcement-text {
  font-size: 18px;
  font-weight: 600;
  color: #0c1b09;
  text-align: center;
}

.logo-header {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  gap: 24px;
}

#natural-logo {
  font-size: 22px;
  color: #ffffff;
}

.nav-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon-button {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #dcdcdc;
  border-radius: 6px;
}

.icon-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.icon-button svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

.icon-button:hover svg {
  color: #ffffff;
}

.menu-container {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #0c1b09;
  border: 1px solid #3d493a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 6px 6px;
  min-width: 210px;
  z-index: 10000;
  border-radius: 12px;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 12px;
  border: none;
  background-color: #0c1b09;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  color: #ffffff;
  border-radius: 6px;
}

.dropdown-menu button:hover {
  background-color: #24391f;
}

.dropdown-menu button.highlighted {
  background-color: rgba(127, 242, 87, 0.1);
  box-shadow: 0 0 0 2px rgba(127, 242, 87, 0.5);
  transition: all 0.3s ease-out;
}

/* Remove the absolute positioning for highlighted menu items */
.dropdown-menu button.highlighted {
  position: relative;
  top: auto;
  right: auto;
  width: 100%;
  z-index: auto;
  display: flex;
}

.toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0c1b09;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #7ff257;
  box-shadow: 0 0 10px rgba(127, 242, 87, 0.3);
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.contact-link {
  margin-left: 10px;
}

.contact-link a {
  color: #dcdcdc;
  font-size: 16px;
  text-decoration: none;
  font-weight: 500;
}

.contact-link a:hover {
  color: #ffffff;
}

@media (max-width: 1024px) {
  .hero {
    flex-direction: column;
    padding: 40px 20px;
  }

  .text-content,
  .image-container {
    width: 100%;
    max-width: 100%;
  }
}

.text-content {
  max-width: 550px;
  text-align: left;
  flex: 1;
}

.text-content.centered {
  max-width: 700px;
  text-align: center;
}

#header {
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 50px;
  line-height: 1.2;
  margin-bottom: 16px;
}

.highlight {
  color: #7ff257;
}

#sub-header {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 36px;
  color: #dcdcdc;
}

.email-form {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  padding: 12px;
  background-color: #1d2e19;
  border-radius: 10px;
}

.email-form-2 {
  display: flex;
  padding: 12px;
  background-color: #1d2e19;
  border-radius: 8px;
  overflow: hidden;
}

.input-button-group {
  display: flex;
  width: 100%;
  overflow: hidden;
}

#email,
#email-2 {
  flex: 1;
  font-size: 18px;
  padding: 12px 16px;
  border: none;
  background-color: #1d2e19;
  color: #ffffff;
  cursor: text;
  outline: none;
}

#email-2 {
  background-color: #1d2e19;
}

/* For Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #1d2e19 inset;
  box-shadow: 0 0 0px 1000px #1d2e19 inset;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
}

/* For other browsers */
input,
input:focus {
  outline: none;
}

#email::placeholder,
#email-2::placeholder {
  color: #90968e;
}

.input-button {
  padding: 12px 24px;
  margin-right: 12px;
  font-weight: 600;
  font-size: 18px;
  background-color: #7ff257;
  color: #0c1b09;
  border: none;
  cursor: pointer;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.5);
  border-radius: 100px;
}

.input-button-2 {
  padding: 12px 24px;
  font-weight: 600;
  font-size: 18px;
  background-color: #0C1B09;
  color: #ffffff;
  border: 1px solid #3D493A;
  cursor: pointer;
  border-radius: 100px;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  border-radius: 8px;
  padding: 20px;
}

.background-image {
  max-width: 450px;
  width: 100%;
  pointer-events: none;
  user-select: none;
}

.demo-section-container {
  position: relative;
  max-width: 1100px;
  margin: 40px auto;
  padding: 0 40px;
  transition: background-color 0.3s ease;
}

.demo-section-container.crm .demo-section {
  background-color: #c6abff;
}

.demo-section-container.erp .demo-section {
  background-color: #ffde69;
}

.demo-section-container.finance .demo-section {
  background-color: #8aeaff;
}

.demo-section-container.mobile .demo-section {
  background-color: #ff92c6;
}

.demo-section {
  background-color: #c6abff;
  border-radius: 20px;
  overflow: hidden;
  padding: 30px;
}

.demo-content {
  position: relative;
  margin-top: 0px;
  padding-top: 0px;
}

.demo-section-container-2 {
  position: relative;
  max-width: 1100px;
  margin: 0px auto;
  padding: 0 40px;
  margin-bottom: 100px;
}

.demo-section-2 {
  background-color: #ffde69;
  border-radius: 20px;
  overflow: hidden;
  padding: 30px;
}

.demo-content-2 {
  position: relative;
}

.demo-image {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mobile-demo-image {
  height: 586px;
  width: auto;
  margin: auto;
  object-fit: contain;
  object-position: center;
}

.demo-toast {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: #0c1b09;
  font-weight: 500;
  color: #ffffff;
  padding: 16px;
  border-radius: 10px;
  font-size: 18px;
  z-index: 1000;
  opacity: 0;
  transform: translateY(20px);
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;
  pointer-events: none;
}

.demo-toast.visible {
  opacity: 1;
  transform: translateY(0);
}

.demo-toast.hidden {
  opacity: 0;
  transform: translateY(20px);
}

.toast-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.toast-icon {
  font-size: 20px;
  color: #7ff257;
}

.demo-component {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #0c1b09;
  border-radius: 12px;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.demo-component.closed {
  width: 56px;
  height: 56px;
  border-radius: 10px;
  right: 0;
  bottom: 60px;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.demo-component.open {
  width: 320px;
  height: auto;
  max-height: 85vh;
  border-radius: 12px;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.demo-component.open:not(.INITIAL) {
  width: 360px;
}

.demo-component.open .demo-component-content {
  opacity: 1;
  transform: translateY(0);
}

.demo-component-closed {
  width: 56px;
  height: 56px;
  background-color: #7ff257;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;

  right: 0;
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.demo-component-closed-icon {
  font-size: 28px;
  color: #0c1b09;
  transition: transform 0.3s ease-in-out;
}

.demo-component-closed:hover .demo-component-closed-icon {
  transform: scale(1.1);
}

.demo-component-content {
  padding: 20px;
}

.demo-component-heading {
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 12px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.wave-emoji {
  margin-right: 8px; /* Adjust this value to increase or decrease the space */
}

.demo-input-group {
  position: relative;
  margin-bottom: 12px;
}

.demo-input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}



.demo-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

.blinking-cursor {
  position: absolute;
  left: 14px; /* Align with the start of the input text */
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 24px;
  background-color: #7ff257;
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.demo-try-button {
  padding: 12px 20px;
  background-color: #ffffff;
  border: none;
  border-radius: 100px;
  color: #0c1b09;
  margin-top: 12px;
  margin-bottom: 6px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.3s ease;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.demo-try-button.pulse {
  animation: pulse 1.5s infinite;
}

.demo-table {
  max-height: 0;
  border-radius: 10px;
  margin-top: 12px;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.demo-component.TABLE_SHOWN .demo-table,
.demo-component.JEN_FELL_THROUGH .demo-table {
  max-height: 320px; /* Increased from 300px */
}

.demo-table table {
  width: 100%;
  border-collapse: collapse;
}

.demo-table th,
.demo-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #1a3d14;
  font-size: 16px;
  font-weight: 500;
}

.demo-table th {
  background-color: #1d3f17;
  color: #ffffff;
}

.demo-table td {
  color: #ffffff;
}

.demo-dropdown {
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
  align-items: center;
  color: #dcdcdc;
  font-weight: 500;
  font-size: 16px;
  transition: 0.3s ease;
  border-radius: 10px;
}

.dropdown-arrow {
  font-size: 14px;
  color: #dcdcdc;
  margin-left: 5px;
}

.status-tag {
  display: inline-block;
  padding: 4px 7px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.status-tag-closed {
  background-color: #7ff257;
  color: #0c1b09;
}

.status-tag-open {
  background-color: #ffaf38;
  color: #000000;
}

.demo-filters {
  display: flex;
  gap: 12px; /* Adjust this value to increase or decrease the gap */
  justify-content: center;
  margin-bottom: 0px;
}

.filter-button {
  padding: 10px 18px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 100px;
}

.filter-button.crm {
  color: #c6abff;
}

.filter-button.erp {
  color: #ffde69;
}

.filter-button.finance {
  color: #8aeaff;
}

.filter-button.mobile {
  color: #ff92c6;
}

.filter-button:hover {
  background-color: rgba(127, 242, 87, 0.1);
}

.filter-button.active.crm {
  background-color: #c6abff;
  color: #0c1b09;
  border-color: #c6abff;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.5);
}

.filter-button.active.erp {
  background-color: #ffde69;
  color: #0c1b09;
  border-color: #ffde69;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.5);
}

.filter-button.active.finance {
  background-color: #8aeaff;
  color: #0c1b09;
  border-color: #8aeaff;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.5);
}

.filter-button.active.mobile {
  background-color: #ff92c6;
  color: #0c1b09;
  border-color: #ff92c6;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.5);
}

@media (max-width: 768px) {
  .demo-section-container,
  .demo-section,
  .demo-component,
  .demo-section-container-2 {
    display: none;
  }
}

.features-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 100px;
  
}

#features-text {
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 46px;
  color: #ffffff;
  text-align: center;
}

.features-section {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 24px;
}

.features-2 {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  align-items: stretch;
}

.feature-item-2 {
  flex: 1;
  min-width: 0;
  text-align: left;
  padding: 28px;
  background-color: #24391f;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.feature-item-2 img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 10px;
}

.founders {
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 100px;
}

.story {
  max-width: 800px;
  margin: 0 auto;
  padding: 5px 0px;
  margin-bottom: 100px;
}

#story-heading {
  font-family: "Bricolage Grotesque", sans-serif;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 46px;
  color: #ffffff;
  text-align: center;
}

#story-heading .highlight {
  color: #7ff257;
}

#story-text {
  font-size: 18px;
  line-height: 1.6;
  color: #dcdcdc;
  margin-bottom: 32px;
  text-align: left;
}

.story ul {
  list-style-type: none;
  padding-left: 20px;
  line-height: 1.6;
  color: #dcdcdc;
  font-size: 18px;
  margin-bottom: 46px;
}

.story ul li {
  position: relative;
  padding-left: 20px;
  color: #dcdcdc;
  line-height: 1.6;
  margin-bottom: 10px;
  font-size: 18px;
}

.story ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  background-color: #7ff257;
}

.story ul li strong {
  font-weight: 700;
  color: #ffffff;
}

.features {
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 24px;
}

.feature-item {
  font-family: "Inter", sans-serif;
  background-color: #0c1b09;
  border: 1px solid #3d493a;
  padding: 28px;
  flex: 1;
  text-align: left;
  border-radius: 8px;
}

#feature-heading {
  font-size: 18px;
  font-family: "Inter", sans-serif;
  margin-top: 6px;
  color: #ffffff;
}

#feature-text {
  font-size: 18px;
  line-height: 1.5;
  color: #dcdcdc;
  margin-top: -4px;
  margin-bottom: 0px;
}

#icon {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
  pointer-events: none;
  user-select: none;
}

.dev-section-container {
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 80px;
}

.dev-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

footer {
  padding: 40px 0;
  background-color: #0c1b09;
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #3d493a;
}

.inner-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 40px;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 24px;
}

a {
  color: #7ff257;
  text-decoration: none;
  text-shadow: 0 0 5px rgba(127, 242, 87, 0.3);
}

#logo-text {
  text-align: center;
  font-weight: 500;
  color: #dcdcdc;
  font-size: 18px;
  margin-bottom: 16px;
}

.logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 56px;
  margin-top: 32px;
  margin-bottom: 24px;
}

.trusted-by-text {
  text-align: center;
  color: #dcdcdc;
  font-size: 18px;
  margin-bottom: 24px;
  font-weight: 500;
}

.logo-images {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 64px;
}

#leapyear {
  width: auto;
  height: 28px;
  pointer-events: none;
  user-select: none;
  filter: brightness(1.5);
}

#pioneer {
  width: auto;
  height: 55px;
  pointer-events: none;
  user-select: none;
  filter: brightness(1.5);
}


#nvidia {
  width: auto;
  height: 65px;
  pointer-events: none;
  user-select: none;
  filter: brightness(1.5);
}

#minno {
  width: auto;
  height: 24px;
  pointer-events: none;
  user-select: none;
  filter: brightness(1.5);
}

#icon {
  width: auto;
  height: 24px;
}

/* Media Queries for Very Small Screens */
@media (max-width: 400px) {
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
  }

  .demo-gif {
    width: 100%;
    height: auto;
    border-radius: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }


  .logo-header {
    padding-left: 0px;
    flex: 1;
    text-align: left;
  }

  .hero {
    flex-direction: column;
    padding: 30px 10px;
  }

  .text-content {
    text-align: center;
    max-width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  #header {
    font-size: 32px;
  }

  #sub-header {
    font-size: 18px;
  }

  .image-container {
    justify-content: center;
  }

  .background-image {
    max-width: 100%;
  }

  .features {
    flex-direction: column;
    gap: 20px;
  }

  .feature-item {
    padding: 20px;
  }

  .logos {
    gap: 20px;
  }

  #mit {
    width: auto;
    height: 30px;
    pointer-events: none;
    user-select: none;
  }

  #texas {
    width: auto;
    height: 85px;
    pointer-events: none;
    user-select: none;
  }

  .inner-footer {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .footer-logo {
    margin-bottom: 10px;
  }

  #texas {
    height: 50px;
  }

  #mit {
    height: 20px;
  }

  .input-button-group {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .input-button {
    padding: 10px 15px;
    font-size: 16px;
  }

  #email,
  #email-2 {
    padding: 10px;
    font-size: 16px;
  }

  .contact-link a {
    color: #8f8f8f;
    text-decoration: none;
    padding-right: 0px;
  }
}

/* General Tablet Style */
@media (min-width: 401px) and (max-width: 1024px) {
  .hero {
    flex-direction: column;
    padding: 40px 20px;
  }

  .text-content {
    text-align: center;
    max-width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  #header {
    font-size: 36px;
  }

  #sub-header {
    font-size: 20px;
  }

  .image-container {
    justify-content: center;
  }

  .background-image {
    max-width: 80%;
  }

  .features {
    flex-direction: column;
    gap: 30px;
  }

  .feature-item {
    padding: 25px;
  }

  .logos {
    gap: 40px;
  }

  #texas {
    height: 60px;
  }

  #mit {
    height: 20px;
  }

  .inner-footer {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .footer-logo {
    margin-bottom: 15px;
  }

  .input-button-group {
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .input-button {
    padding: 10px 20px;
    font-size: 18px;
  }

  #email,
  #email-2 {
    padding: 15px;
    font-size: 18px;
  }

  .contact-link a {
    padding-right: 15px;
  }
}

.icon-button.highlighted,
.dropdown-menu button.highlighted,
.toggle-container.highlighted,
#copy-link.highlighted,
#menu-button.highlighted,
#email.highlighted,
.features.highlighted,
.founders.highlighted,
.contact-link a.highlighted {
  background-color: rgba(127, 242, 87, 0.1);
  box-shadow:
    0 0 0 2px rgba(127, 242, 87, 0.5),
    0 0 10px rgba(127, 242, 87, 0.3);
  border-radius: 6px;
  transition: all 0.3s ease-out;
}

.fading {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none;
  }

  .announcement {
    padding: 10px 0;
    display: none;
  }
}

.close-tooltip {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}

/* Add this at the end of your Clippy.css file */
#features-tooltip {
  position: absolute;
  width: 1px;
  height: 1px;
  background: transparent;
}

.icon-button svg,
.dropdown-menu button svg,
.contact-link a svg {
  color: #8f8f8f;
  transition: color 0.3s ease;
}

.icon-button:hover svg,
.dropdown-menu button:hover svg,
.contact-link a:hover svg {
  color: #ffffff;
}

/* Increase icon size for mobile and tablet */
@media (max-width: 1024px) {
  .icon-button {
    font-size: 24px;
    width: 48px;
    height: 48px;
  }
}

/* Ensure menu and tooltips are at the front */
.dropdown-menu {
  z-index: 10000;
}

.react-tooltip {
  z-index: 9999 !important;
}

@media (max-width: 1024px) {
  .header-content {
    flex-direction: column;
    align-items: center;
  }

  .nav-actions {
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .react-tooltip {
    max-width: 80vw !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

@media (max-width: 768px) {
  .demo-filters {
    display: none;
  }
}

/* Add or modify these media queries at the end of your CSS file */
@media (max-width: 480px) {
  .header-content {
    padding: 10px 15px;
  }

  .nav-actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .icon-button {
    font-size: 18px;
    width: 36px;
    height: 36px;
  }

  .contact-link a {
    font-size: 14px;
  }

  #natural-logo {
    font-size: 18px;
  }
}

/* Add these styles for the icons */
.icon-button svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

@media (max-width: 768px) {
  .icon-button svg {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 480px) {
  .icon-button svg {
    width: 16px;
    height: 16px;
  }
}

.demo-heading {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 16px;
  margin-bottom: 8px;
  text-align: left;
}

@media (max-width: 360px) {
  .header-content {
    padding: 10px;
  }

  .nav-actions {
    gap: 5px;
  }

  .icon-button {
    font-size: 16px;
    width: 32px;
    height: 32px;
  }

  .contact-link a {
    font-size: 12px;
  }

  #natural-logo {
    font-size: 16px;
  }
}

.icon-button {
  /* ... existing styles ... */
  color: #dcdcdc; /* Ensure the icon color is #DCDCDC */
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

@media (max-width: 768px) {
  .icon-button svg {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 480px) {
  .icon-button svg {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 360px) {
  .header-content {
    padding: 10px;
  }

  .nav-actions {
    gap: 5px;
  }

  .icon-button {
    font-size: 16px;
    width: 32px;
    height: 32px;
  }

  .contact-link a {
    font-size: 12px;
  }

  #natural-logo {
    font-size: 16px;
  }
}

.icon-button {
  /* ... existing styles ... */
  color: #dcdcdc; /* Ensure the icon color is #DCDCDC */
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button svg {
  width: 20px;
  height: 20px;
  fill: currentColor;
}

@media (max-width: 768px) {
  .icon-button svg {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 480px) {
  .icon-button svg {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 360px) {
  .header-content {
    padding: 10px;
  }

  .nav-actions {
    gap: 5px;
  }

  .icon-button {
    font-size: 16px;
    width: 32px;
    height: 32px;
  }

  .contact-link a {
    font-size: 12px;
  }

  #natural-logo {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    width: 100%;
    box-sizing: border-box;
  }


.quick-features {
  padding-right: 20px;
  padding-left: 20px;
}

  .features-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
  }

  .main-features {
    padding: 20px;
  }

  .dev-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .story {
    padding: 20px;
  }

  .logos {
    flex-direction: column;
  }

  .dev-section-container {
    padding: 20px;
  }

  .logo-header {
    padding-left: 0;
  }

  .nav-actions {
    margin-top: 0;
    gap: 10px;
    display: flex;
    align-items: center;
  }

  .icon-button {
    width: 32px;
    height: 32px;
    font-size: 18px;
  }

  .contact-link {
    margin-left: 0;
  }
  .contact-link a {
    font-size: 14px;
    white-space: nowrap;
  }

  /* Ensure the menu is visible */
  .menu-container {
    display: flex;
  }

  .dropdown-menu {
    right: 0;
    left: auto;
    z-index: 1003;
  }
}

/* Add or modify these media queries at the end of your CSS file */
@media (max-width: 480px) {
  .header-content {
    padding: 10px 15px;
  }

  .nav-actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .icon-button {
    font-size: 18px;
    width: 36px;
    height: 36px;
  }

  .contact-link a {
    font-size: 14px;
  }

  #natural-logo {
    font-size: 18px;
  }
}

@media (max-width: 360px) {
  .header-content {
    padding: 10px;
  }

  .nav-actions {
    gap: 5px;
  }

  .icon-button {
    font-size: 16px;
    width: 32px;
    height: 32px;
  }

  .contact-link a {
    font-size: 12px;
  }

  #natural-logo {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .inner-footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .footer-logo {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
  }

  .contact-link {
    margin-left: 0;
    margin-bottom: 0;
  }

  .contact-link a {
    font-size: 14px;
    padding: 5px 10px;
  }

  .input-email-form-2 {
    width: 100%;
  }

  .email-form-2 {
    flex-direction: column;
  }

  #email-2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .inner-footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .footer-logo {
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }

  .contact-link {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .contact-link a {
    font-size: 14px;
  }

  .input-email-form-2 {
    width: 100%;
  }

  .email-form-2 {
    flex-direction: column;
  }

  #email-2 {
    margin-bottom: 10px;
  }
}

/* Styles that need to be global can be left outside the .Clippy wrapper */
body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
  }
}

/* ... rest of the styles ... */

/* Style the scrollbar for WebKit browsers */
.demo-buttons::-webkit-scrollbar {
  height: 6px; /* Height of the horizontal scrollbar */
}

.demo-buttons::-webkit-scrollbar-track {
  background: #193015; /* Background of the scrollbar track */
  border-radius: 10px;
}

.demo-buttons::-webkit-scrollbar-thumb {
  background: #203d1b; /* Color of the scrollbar thumb */
  border-radius: 10px;
}

.demo-buttons::-webkit-scrollbar-thumb:hover {
  background: #203d1b;  /* Color of the scrollbar thumb on hover */
}

/* Style the scrollbar for Firefox */
.demo-buttons {
  scrollbar-width: thin; /* Make the scrollbar thinner */
  scrollbar-color: #203d1b #193015; /* Thumb color and track color */
}

@keyframes shimmer {
  0% {
    background-position: -300px 0; /* Start position */
  }
  50% {
    background-position: 300px 0; /* Midway position */
  }
  100% {
    background-position: -300px 0; /* End position, same as start for seamless loop */
  }
}

.shimmer {
  background: linear-gradient(
    to right,
    #193015 0%,
    #24391f 20%,
    #193015 40%,
    #193015 100%
  );
  background-size: 300% auto; /* Adjusted to match the keyframes */
  animation: shimmer 6s linear infinite; /* Increased duration for longer delay */
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust this value as needed */
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(127, 242, 87, 0.3);
  border-radius: 50%;
  border-top: 4px solid #7FF257;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.chart-container {
  margin-top: 20px;
}

.table-container {
  margin-top: 20px;
  max-height: 300px; /* Adjust this value as needed */
  overflow-y: auto;
}

.customer-support-container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.customer-support-message {
  background-color: #24391f;
  color: #ffffff;
  padding: 12px 16px;
  border-radius: 12px;
  font-size: 16px;
  max-width: 80%;
  align-self: flex-start;
}


.demo-input {
  background-color: #193015;
  color: #637360;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 12px;
  font-size: 18px;
  width: 100%;
  box-sizing: border-box;
}

.demo-input-cursor {
  display: inline-block;
  width: 2px;
  height: 18px;
  background-color: #7ff257;
  margin-left: 2px;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

/* ... (rest of the styles) */
